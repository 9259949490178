import React from "react";
import { Search } from "lucide-react";

const SearchBar = ({ searchTerm, setSearchTerm }) => (
  <div className="mb-6 relative h-max items-center">
    <Search className="absolute left-3 top-3 text-gray-400" size={20} />
    <input
      type="text"
      placeholder="Search by Name, College, or Email"
      className="w-full p-3 pl-10 rounded-2xl border border-slate-200"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
);

export default SearchBar;
