import React, { useState, useEffect } from "react";
import registerStore from "../../store/registrationStore";
import db from '../../firebase';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const RegistrationPage = (props) => {
  const [participants, setParticipants] = useState([]);
  const [file, setFile] = useState(null);
  const [collegeName, setCollegeName] = useState("");
  const [collegeAddress, setCollegeAddress] = useState("");
  const [role, setRole] = useState("");
  const [repName, setRepName] = useState("");
  const [repContact, setRepContact] = useState("");
  const [repEmail, setRepEmail] = useState("");
  const [uploadLater, setUploadLater] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const storage = getStorage();

  useEffect(() => {
    if(props.email){
      // fetch data and assign
      const docRef = doc(db, "registrations", props.email);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCollegeName(data.collegeName);
          setCollegeAddress(data.collegeAddress);
          setRole(data.role);
          setRepName(data.repName);
          setRepContact(data.repContact);
          setRepEmail(data.repEmail);
          setParticipants(JSON.parse(data.participants));
          setUploadLater(data.uploadLater === "Yes");
          setFile(data.fileURL);
        }
      });
    }
  }, [props.email]);

  const addParticipant = () => {
    setParticipants([...participants, { course: "", count: "" }]);
  };

  const updateParticipant = (index, key, value) => {
    const newParticipants = [...participants];
    newParticipants[index][key] = value;
    setParticipants(newParticipants);
  };

  const removeParticipant = (index) => {
    const newParticipants = participants.filter((_, i) => i !== index);
    setParticipants(newParticipants);
  };

  const handleFileChange = (e) => {
    // only accept csv or xls files
    if (!e.target.files[0].name.match(/\.(csv|xls|xlsx)$/)) {
      alert("Please upload a valid file");
      return;
    }
    setFile(e.target.files[0]);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!collegeName)
      newErrors.collegeName = "College/University Name is required";
    if (!collegeAddress)
      newErrors.collegeAddress = "College/University Address is required";
    if (!role) newErrors.role = "Role of Representative is required";
    if (!repName) newErrors.repName = "Representative Name is required";
    if (!repContact)
      newErrors.repContact = "Representative Contact is required";
    if (!repEmail) newErrors.repEmail = "Representative Email is required";
    if (participants.length === 0)
      newErrors.participants =
        "Please give an estimate of number of participants";
    if (file === null && uploadLater === false)
      newErrors.file = "Please upload a file (or) select Upload Later";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (e) => {

    if (validateFields()) {
      setIsSubmitting(true);

      const data = {
        collegeName,
        collegeAddress,
        role,
        repName,
        repContact,
        repEmail,
        uploadLater: uploadLater ? "Yes" : "No",
        participants: JSON.stringify(participants), // Convert array to string
      };

      try {
        if (file && !uploadLater) {
          // Create a reference in Firebase storage
          const fileRef = ref(storage, `participants/${file.name}`);

          // Upload the file to Firebase Storage
          await uploadBytes(fileRef, file);

          // Get the download URL of the uploaded file
          const fileURL = await getDownloadURL(fileRef);

          // Add the file URL to the data object
          data.fileURL = fileURL;
        }
        const docRef = doc(db, "registrations", repEmail);
        await setDoc(docRef, data);

        alert("Registration successful!");
        setIsSubmitting(false);
        if (!props.isUpload) {
          props.setModalIsOpen(false);
        }
    } catch (error) {
        console.error("Error during registration:", error);
        console.error(error);
      alert("There was an issue with the registration process.");
      setIsSubmitting(false);
    }
    } else {
      console.log("Form is invalid. Fix errors.");
    }
  };

  return (
      <div className="flex flex-col items-center">
        <div className="text-center text-[#1a365d] text-3xl font-bold font-montserrat mb-8">
          { props.isUpload ? "College Details" :  "Registering College"}
        </div>
        <div className="flex flex-row items-center gap-6 w-full justify-center items-center  px-20 text-start">
          <div className="w-full flex flex-col gap-6">
            <div className="flex gap-4 items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                College / University Name
              </label>
              <div className="w-3/5 flex flex-col">
                <input
                  type="text"
                  className="h-11 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  placeholder="Enter college/university name"
                  value={collegeName}
                  onChange={(e) => {
                    setCollegeName(e.target.value);
                    if (errors.collegeName)
                      setErrors({ ...errors, collegeName: "" });
                  }}
                />
                {errors.collegeName && (
                  <span className="text-red-500">{errors.collegeName}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                College / University Address
              </label>
              <div className="w-3/5 flex flex-col">
                <input
                  type="text"
                  className="h-11 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  placeholder="Enter address"
                  value={collegeAddress}
                  onChange={(e) => {
                    setCollegeAddress(e.target.value);
                    if (errors.collegeAddress)
                      setErrors({ ...errors, collegeAddress: "" });
                  }}
                />
                {errors.collegeAddress && (
                  <span className="text-red-500">{errors.collegeAddress}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                Role of Representative
              </label>
              <div className="w-3/5 flex flex-col">
                <select
                  className="h-12 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    if (errors.role) setErrors({ ...errors, role: "" });
                  }}
                >
                  <option>Select role</option>
                  {registerStore.roles.map((role) => (
                    <option key={role.id}>{role.name}</option>
                  ))}
                </select>
                {errors.role && (
                  <span className="text-red-500">{errors.role}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                Representative Name
              </label>
              <div className="w-3/5 flex flex-col">
                <input
                  type="text"
                  className="h-11 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  placeholder="Enter name"
                  value={repName}
                  onChange={(e) => {
                    setRepName(e.target.value);
                    if (errors.repName) setErrors({ ...errors, repName: "" });
                  }}
                />
                {errors.repName && (
                  <span className="text-red-500">{errors.repName}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                Representative Contact
              </label>
              <div className="w-3/5 flex flex-col">
                <input
                  type="text"
                  className="h-11 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  placeholder="Enter contact number"
                  value={repContact}
                  onChange={(e) => {
                    setRepContact(e.target.value);
                    if (errors.repContact)
                      setErrors({ ...errors, repContact: "" });
                  }}
                />
                {errors.repContact && (
                  <span className="text-red-500">{errors.repContact}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                Representative Email
              </label>
              <div className="w-3/5 flex flex-col">
                <input
                  type="email"
                  className="h-11 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                  placeholder="Enter email"
                  value={repEmail}
                  onChange={(e) => {
                    setRepEmail(e.target.value);
                    if (errors.repEmail) setErrors({ ...errors, repEmail: "" });
                  }}
                />
                {errors.repEmail && (
                  <span className="text-red-500">{errors.repEmail}</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-start">
              <label className="w-2/5 text-base font-semibold font-montserrat">
                No. of Participants
              </label>
              <div className="w-3/5 h-max flex flex-col gap-2">
                <button
                  onClick={() => {
                    addParticipant();
                    if (errors.participants)
                      setErrors({ ...errors, participants: "" });
                  }}
                  className="w-full h-11 py-3 bg-slate-100 rounded-lg border border-slate-300"
                >
                  <i className="fas fa-plus"></i>
                </button>
                {errors.participants && (
                  <span className="text-red-500">{errors.participants}</span>
                )}
                <div className="w-50 h-100 flex flex-wrap gap-5 overflow-y-scroll py-2">
                  {participants.map((participant, index) => (
                    <div
                      key={index}
                      className="h-full flex flex-col gap-4 items-center border rounded-xl border-slate-200 p-5 relative"
                    >
                      <i
                        className="fas fa-close absolute top-2 right-2 text-grey-500 cursor-pointer"
                        onClick={() => removeParticipant(index)}
                      ></i>
                      <select
                        value={participant.course}
                        onChange={(e) =>
                          updateParticipant(index, "course", e.target.value)
                        }
                        className="h-1/2 w-full px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                      >
                        <option>Course</option>
                        {registerStore.courses.map((course) => (
                          <option key={course.id}>{course.name}</option>
                        ))}
                      </select>
                      <select
                        value={participant.count}
                        onChange={(e) =>
                          updateParticipant(index, "count", e.target.value)
                        }
                        className="h-1/2 w-full px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                      >
                        <option>Count</option>
                        <option>50-100</option>
                        <option>100-200</option>
                        <option>200-300</option>
                        <option>300+</option>
                      </select>
                      {participant.course === "300+" && (
                        <input
                          value={participant.count}
                          type="number"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[1-9]\d*$/.test(value) || value === "") {
                              updateParticipant(index, "count", value);
                            }
                          }}
                          className="h-1/2 w-20 px-4 py-3 bg-slate-100 rounded-lg border border-slate-300"
                          placeholder="Enter number of participants"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-1 h-80 bg-gray-400" />
          <div className="w-full mt-6 bg-slate-200 rounded-2xl p-6 border flex flex-col items-center">
            <div className="text-[#1a365d] text-4xl font-normal font-montserrat mb-4">
              Participants Sheet
            </div>
            <div className="text-[#1a365d] text-sm font-normal font-['Open Sans'] mb-4">
              File should be CSV/XLS format
            </div>
            <div className="w-full p-4 bg-slate-200 rounded-2xl shadow flex flex-col items-center outline-dashed">
              <label className="cursor-pointer bg-[#1a365d] text-white font-semibold font-montserrat py-3 px-6 rounded-2xl">
                Browse File
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    handleFileChange(e);
                    if (errors.file) setErrors({ ...errors, file: "" });
                  }}
                />
              </label>
              <div className="mt-4 text-[#1a365d] font-semibold">
                {file ? file.name : "No file chosen"}
              </div>
            </div>
            <div className="text-[#1a365d] text-base font-normal mt-6">
              <strong>File should contain the following fields:</strong>
              <li>Participant Name</li>
              <li>Course/Specialisation</li>
              <li>Year of Pursuing</li>
              <li>Percentage</li>
              <li>Contact Number</li>
              <li>Email ID</li>
            </div>
            <div className="flex items-center justify-between w-full mt-6">
              <span className="h-px bg-gray-400 flex-grow"></span>
              <span className="mx-4">OR</span>
              <span className="h-px bg-gray-400 flex-grow"></span>
            </div>
            <div className="flex items-center mt-4">
              <input
                value={uploadLater}
                type="checkbox"
                className="mr-2"
                onChange={(e) => {
                  setUploadLater(e.target.checked);
                  if (errors.file) setErrors({ ...errors, file: "" });
                }}
              />
              <label className="text-[#1a365d] text-base font-normal">
                Upload Later (by 19th Sep)
              </label>
            </div>
            {errors.file && <span className="text-red-500">{errors.file}</span>}
          </div>
        </div>

        <button
        onClick={() => {
          handleRegister();
          }}
          className="w-max p-6 m-6 bg-[#1a365d] text-white text-base font-semibold py-3 rounded-2xl"
        >
          {isSubmitting ? "Registering..." : "Register"}
        </button>
        {errors.submit && <span className="text-red-500">{errors.submit}</span>}
      </div>
  );
};

export default RegistrationPage;
