import React, { useState, useEffect } from "react";
import { GraduationCap, Briefcase, UserCheck } from "lucide-react";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";

const DashboardStats = () => {
  const [registrations, setRegistrations] = useState([]);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [toBeProcessed, setToBeProcessed] = useState(0);

  const [loading, setLoading] = useState(true);
  const fetchRegistrations = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "registrations"));
      const registrationsData = querySnapshot.docs.map(doc => doc.data());
      console.log("Registrations data:", registrationsData);
      setRegistrations(registrationsData);
    } catch (error) {
      console.error("Error fetching registrations:", error);
    }
  };
  
  useEffect(() => {
    fetchRegistrations();
  }, []);

  const getParticipantsCount = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "participants"));
      const participantsCount = querySnapshot.docs.length;
      setParticipantsCount(participantsCount);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  }
  useEffect(() => {
  
    const getCollegesToBeProcessed = () => {
      const collegesToBeProcessed = registrations.filter(registration => registration.processed === false).length;
      setToBeProcessed(collegesToBeProcessed);
    }
    getParticipantsCount();
    getCollegesToBeProcessed();
    setLoading(false);
  }, [registrations]);
  return <div className="flex flex-row gap-6 text-start">
    <div className="p-6">
      <div className="flex items-center space-x-4">
        <div className="bg-gray-300 p-3 rounded-full">
          <Briefcase size={24} />
        </div>
        <div>
          <p className="text-sm text-gray-600">
            Registered Colleges/Universities
          </p>
          <p className="text-2xl font-semibold">{loading ? "" : registrations.length}</p>
        </div>
      </div>
    </div>
    <div className="w-px h-full bg-[#E2E8F0]"></div>
    <div className="p-6">
      <div className="flex items-center space-x-4">
        <div className="bg-gray-300 p-3 rounded-full">
          <UserCheck size={24} />
        </div>
        <div>
          <p className="text-sm text-gray-600">Participants Processed</p>
          <p className="text-2xl font-semibold">{ loading ? "Loading" : participantsCount }</p>
        </div>
      </div>
    </div>
    <div className="w-px h-full bg-[#E2E8F0]"></div>
    <div className="p-6">
      <div className="flex items-center space-x-4">
        <div className="bg-gray-300 p-3 rounded-full">
          <GraduationCap size={24} />
        </div>
        <div>
          <p className="text-sm text-gray-600">Colleges to be processed</p>
          <p className="text-2xl font-semibold">{ loading ? "" : toBeProcessed }</p>
        </div>
      </div>
    </div>
  </div>;
}

export default DashboardStats;