import React, { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import db from "../firebase";

const DateForm = () => {
  const [events, setEvents] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const parseFirebaseTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return new Date();
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const formatTime = (date) => {
    return date.toTimeString().split(" ")[0].substr(0, 5);
  };

  useEffect(() => {
    const eventOrder = [
      "Registration Start Date",
      "Registration Deadline",
      "Workshop starting date",
      "Workshop ending date",
      "Hackathon Date"
    ];
    const initializeEvents = (data) => {
      return eventOrder.map(name => {
        const timestamp = data[name] || { seconds: 0, nanoseconds: 0 };
        const date = parseFirebaseTimestamp(timestamp);
        return {
          name,
          date: formatDate(date),
          time: formatTime(date),
        };
      });
    };
    const getEvents = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, "events", "learnathon-2024");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = initializeEvents(docSnap.data());
          setEvents(data);
        } else {
          console.log("No such document!");
          setEvents(eventOrder.map(name => ({ name, date: '', time: '' })));
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        alert("Failed to fetch events. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    getEvents();
  }, []);

  const editEvents = async (events) => {
    const docRef = doc(db, "events", "learnathon-2024");
    const eventData = events.reduce((acc, event) => {
      const dateTime = new Date(`${event.date}T${event.time}`);
      acc[event.name] = dateTime;
      return acc;
    }, {});
    await setDoc(docRef, eventData, { merge: true });
  };

  const handleChange = (index, field, value) => {
    const newEvents = [...events];
    newEvents[index] = { ...newEvents[index], [field]: value };
    setEvents(newEvents);
    setIsEdited(true);
  };

  const handleSubmit = async () => {
    if (validateDate()) {
      setIsLoading(true);
      try {
        await editEvents(events);
        alert("Changes submitted successfully");
        setIsEdited(false);
      } catch (error) {
        console.error("Error editing events:", error);
        alert("Failed to submit changes. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateDate = () => {
    const dates = events.map(event => new Date(`${event.date}T${event.time}`));
    for (let i = 0; i < dates.length - 1; i++) {
      if (dates[i] >= dates[i + 1]) {
        alert(`${events[i].name} should be earlier than ${events[i + 1].name}`);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  return (
    <div className="p-6 space-y-6">
      {events.length ? (
        events.map((event, index) => (
          <div
            key={index}
            className="flex flex-col sm:flex-row items-start sm:items-center text-start space-y-2 sm:space-y-0 sm:space-x-2"
          >
            <label
              htmlFor={`date-${index}`}
              className="w-full sm:w-1/3 block text-sm font-medium text-gray-700"
            >
              {event.name}
            </label>
            <div className="w-full sm:w-2/3 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <input
                type="date"
                id={`date-${index}`}
                value={event.date}
                onChange={(e) => handleChange(index, "date", e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="time"
                id={`time-${index}`}
                value={event.time}
                onChange={(e) => handleChange(index, "time", e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          </div>
        ))
      ) : (
        <p>No events found. Please add some events.</p>
      )}
      {isEdited && (
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className="w-full sm:w-auto px-4 py-2 sm:px-6 md:px-8 lg:px-12 md:py-3 bg-[#1A365D] shadow-[0px_0px_4px_rgba(0,0,0,0.25)] rounded-lg md:rounded-xl text-white text-sm sm:text-base font-semibold font-montserrat disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? "Submitting..." : "Submit Changes"}
        </button>
      )}
    </div>
  );
};

export default DateForm;