import React, { useEffect, useState } from 'react';
import { useFirebaseData } from './useFirebaseData';
import SearchBar from './SearchBar';
import RegistrationsTable from './RegistrationsTable';
import Pagination from './Pagination';
import RegistrationView from './registrationView';

const CollegeRegistrations = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [registration, setRegistration] = useState(null);
  const itemsPerPage = 10;
  
  const { data: loadNextPage, loadPreviousPage} = useFirebaseData('registrations', itemsPerPage, setRegistrations, setLoading, setError);
  const getData = () => {
  }
  
  useEffect(() => {
    getData();
  }, []);

  const handleNextPage = () => {
    loadNextPage();
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    loadPreviousPage();
    setCurrentPage(currentPage - 1);
  };

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;

  return (
    registration ?
      <RegistrationView registrationId={registration}  setRegistration={setRegistration} /> :
      (
        <div className="w-full h-full flex flex-col justify-between">
          <div className='h-max flex flex-col gap-2.5'>
            <h1 className="text-2xl font-bold text-[#1a365d] text-start mb-6">College / University Registrations</h1>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <RegistrationsTable registrations={registrations} setRegistration={setRegistration} />
          </div>
          <Pagination
            currentPage={currentPage}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            isLastPage={registrations.length < itemsPerPage}
          />
        </div>
      )
  );
};

export default CollegeRegistrations;