import React from 'react';
import collegeImage from "../../assets/images/register-college.svg";
import bgComponent from "../../assets/images/bg-components.png";

const WorkShopDetails = () => {
    return (
        <div id='workshop' className="bg-gradient-to-b from-black via-[#1A365D] to-[#42C8C9]">
        <div className="flex flex-col justify-start items-center gap-20 px-20 pt-20 pb-0 bg-cover bg-center bg-no-repeat w-full" style={{ backgroundImage: `url(${bgComponent})` }} >
          <div className="text-center text-[#E2E8F0] font-montserrat font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
            Workshop
            <div className="text-center text-[#E2E8F0] font-montserrat text-lg sm:text-xl md:text-2xl">
              <span className="font-normal">Learn From </span>
              <span className="font-bold">Experts</span>
            </div>
          </div>
          <div className="md:w-full flex justify-center items-center md:gap-20">
            <div className="w-3/5 flex flex-col justify-start items-start gap-10" >
              <div className="flex flex-col justify-start text-white text-base sm:text-xl text-start gap-10">
                <li>The first part of the learnathon is workshop, where the participant will be trained from basics to advanced concepts.</li>
                <li>There will be two days workshop, where industry experts will be teaching the participants of every college who registers. </li>
              </div>
              <button className="flex flex-row justify-center items-center gap-4 px-12 py-3 shadow-md rounded-md border text-[#FFFFFF] text-lg font-semibold">
                Schedule
                <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
            </div>
            <img
              src={collegeImage}
              alt="Register College"
              className="object-contain w-3/5"
            />
          </div>
          <div className="flex flex-row p-5 justify-start items-center gap-5">
            <div className="flex flex-col justify-start items-center gap-2.5">
              <div className="text-white text-4xl font-bold font-montserrat">Day-1</div>
              <div className="text-white text-base font-bold font-open-sans">Core AI/ML and Data Science Fundamentals</div>
              <div className="flex flex-col justify-center items-center">
                <div className="p-5 bg-slate-200 rounded-[20px] flex flex-col justify-center items-center gap-2.5">
                  <div className="text-[#1a365d] text-xl font-semibold font-montserrat text-start">Session 1: Foundations of AI, Machine Learning, and Data Science</div>
                  <div className="text-[#1a365d] text-base font-normal font-open-sans text-start">
                    Provide a comprehensive overview of AI, ML, and Data Science, establishing a foundation for more advanced topics.<br/>
                    <li> Introduction to Artificial Intelligence (AI)</li>
                    <li> Machine Learning (ML) Basics</li>
                    <li> Data Science Overview </li>
                    <li> Case Study Discussion</li>
                    <li> Hands-on Activities </li>
                  </div>
                </div>
                <div className="w-0 h-10 border border-white"></div>
                <div className="p-5 bg-slate-200 rounded-[20px] flex flex-col justify-center items-center gap-2.5">
                  <div className="text-[#1a365d] text-xl font-semibold font-montserrat text-start">Session 2: Data Preprocessing and Exploratory Data Analysis</div>
                  <div className="text-[#1a365d] text-base font-normal font-open-sans text-start">
                    Equip participants with skills for data preprocessing and exploratory data analysis (EDA), foundational for model building.<br/>
                    <li> Data Preprocessing Techniques</li>
                    <li> Exploratory Data Analysis (EDA)</li>
                    <li> Case Study Discussion</li>
                    <li> Hands-on Activities</li>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-px bg-gradient-to-b from-transparent via-white to-transparent"></div>
            <div className="flex flex-col justify-start items-center gap-2.5">
              <div className="text-white text-4xl font-bold font-montserrat">Day-2</div>
              <div className="text-white text-base font-bold font-open-sans">Advanced Topics in AI/ML and Introduction to Deep Learning and Generative AI</div>
              <div className="flex flex-col justify-start items-center">
                <div className="p-5 bg-slate-200 rounded-[20px] flex flex-col justify-center items-center gap-2.5">
                  <div className="text-[#1a365d] text-xl font-semibold font-montserrat text-start">Session 3: Advanced Machine Learning and Introduction to Deep Learning</div>
                  <div className="text-[#1a365d] text-base font-normal font-open-sans text-start">
                    Explore advanced machine learning techniques and introduce deep learning concepts.<br/>
                    <li>Advanced Machine Learning Techniques</li>
                    <li>Introduction to Deep Learning</li>
                    <li>Case Study Discussion</li>
                    <li>Hands-on Activities</li>
                  </div>
                </div>
                <div className="w-0 h-10 border border-white"></div>
                <div className="p-5 bg-slate-200 rounded-[20px] flex flex-col justify-center items-center gap-2.5">
                  <div className="text-[#1a365d] text-xl font-semibold font-montserrat text-start">Session 4: Generative AI and Large Language Models (LLMs)</div>
                  <div className="text-[#1a365d] text-base font-normal font-open-sans text-start">
                    Introduce Generative AI and Large Language Models, exploring their capabilities and applications.<br/>
                    <li>Generative AI Overview</li>
                    <li>Introduction to Large Language Models (LLMs)</li>
                    <li>Case Study Discussion</li>
                    <li>Hands-on Activities</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default WorkShopDetails;