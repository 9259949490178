import React from "react";
import { Calendar } from "lucide-react";
import DateForm from "../../components/dateForm";
import DashboardStats from "../../components/dashboardStats";

const Dashboard = () => {
  const today = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <div className="flex-1 p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4 text-start">
          <div>
            <h1 className="text-2xl font-bold">Hello Admin</h1>
            <p className="text-gray-600">
              Track registrations, participants and manage learnathon here
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-gray-600">{today}</span>
            <button className="bg-gray-300 p-2 rounded-full">
              <Calendar size={20} />
            </button>
          </div>
        </div>
      </div>
      <div className="w-max h-full flex flex-col gap-2.5">
        <div className="w-full h-px bg-[#E2E8F0]" ></div>
        <DashboardStats />
        <div className="w-full h-px bg-[#E2E8F0]" ></div>
        < DateForm />
      </div>
    </div>
  );
};

export default Dashboard;

