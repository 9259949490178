import React from 'react';
import certificateImage from "../../assets/images/certificate.svg";
import careerGuidance from "../../assets/images/career-guidance.svg";
import internshipImage from "../../assets/images/internship.svg";
import cash from "../../assets/images/cash.svg";
import bgComponent from "../../assets/images/bg-components.png";

const RewardDetails = () => {
    return (
        <div className="h-max bg-gradient-to-b from-black via-[#1A365D] to-[#3771C3]">
            <div className="relative flex flex-col justify-center items-center bg-cover bg-center pb-10" style={{ backgroundImage: `url(${bgComponent})` }}>
                <div className="p-6 md:p-20 flex flex-col md:flex-row items-center justify-center gap-6 md:gap-12">
                    <div className="flex flex-col items-center gap-4 text-center md:text-left">
                        <img className='h-32 md:h-48' src={certificateImage} alt="Winner/Participation" />
                        <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
                            Winner/Participation<br />Certificate
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-center md:text-left">
                        <img className='h-32 md:h-48' src={internshipImage} alt="Internship" />
                        <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
                            Internship Opportunity
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-center md:text-left">
                        <img className='h-32 md:h-48 object-cover object-contain' src={careerGuidance} alt="Career" />
                        <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
                            Career Guidance
                        </div>
                    </div>
                </div>
                <div className="text-white text-opacity-10 text-[8vw] md:text-[10vw] font-serif italic font-extrabold text-center">
                    Rewards
                </div>
                <div className="flex flex-col items-center gap-2 mt-6 md:mt-12">
                    <img className='h-32 md:h-48' src={cash} alt="Cash" />
                    <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
                        Cash Prize
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewardDetails;