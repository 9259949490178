import React, { useState } from "react";

const ParticipantsTable = ({ participants }) => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const sortedParticipants = [...participants].sort((a, b) => {
    if (sortConfig.key) {
      const sortValueA = a[sortConfig.key].toString().toLowerCase();
      const sortValueB = b[sortConfig.key].toString().toLowerCase();

      if (sortValueA < sortValueB) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (sortValueA > sortValueB) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="overflow-x-auto h-max">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {[
              { key: "id", label: "Reg ID" },
              { key: "name", label: "Name" },
              { key: "course", label: "Course" },
              { key: "collegeName", label: "College Name" },
              { key: "year_of_pursuing", label: "Year of Pursuing" },
              { key: "contact_number", label: "Contact" },
            ].map(({ key, label }) => (
              <th
                key={key}
                className="px-6 py-3 text-left text-xs font-medium text-[#1a365d] uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {label}
                {sortConfig.key === key && (
                  <span className="ml-2 text-sm">
                    {sortConfig.direction === "ascending" ? "▲" : "▼"}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-start">
          {sortedParticipants.map((reg) => (
            <tr key={reg.id} className="cursor-pointer hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.course}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.collegeName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.year_of_pursuing}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.contact_number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantsTable;
