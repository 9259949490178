import React from 'react';
import heroBackground from '../../assets/images/hero-bg.svg';
import homePageImage from '../../assets/images/homePageImage.svg';
import scrollToSection from '../../helpers/scrollToSection';

const HomePage = (props) => {
  return (
    <section id="home">
      <div className="min-h-screen flex flex-col justify-end md:flex-row overflow-visible">
        {/* Left Side - Text Content */}
        <div className="w-full md:w-1/2 flex flex-col justify-end items-start p-4 pb-20 md:p-8 lg:p-12 z-10">
          <div className="flex flex-col justify-start items-start gap-48 w-full">
            <div className="flex flex-col justify-start items-start gap-3 md:gap-4 lg:gap-6">
              <div className="flex flex-col justify-start items-start gap-1 md:gap-2 lg:gap-4">
                <div className="text-[#4A5568] text-lg sm:text-xl md:text-2xl font-medium font-montserrat">
                  I Love HR presents
                </div>
                <div className="text-[#4A5568] text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold font-montserrat">
                  Learnathon 2024
                </div>
                <div className="text-[#4A5568] text-base sm:text-lg md:text-xl text-start font-normal font-montserrat">
                  Join the ultimate learning experience for college students
                </div>
              </div>
              <div className="text-[#4A5568] text-base sm:text-lg md:text-xl font-medium font-montserrat">
                September 25-30th
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-start items-start gap-3 md:gap-4 lg:gap-6 w-full sm:w-auto">
              <button
                onClick={() => props.setModalIsOpen(true)}
                className="w-full sm:w-auto px-4 py-2 sm:px-6 md:px-8 lg:px-12 md:py-3 bg-[#1A365D] shadow-[0px_0px_4px_rgba(0,0,0,0.25)] rounded-lg md:rounded-xl">
                <span className="text-white text-sm sm:text-base font-semibold font-montserrat">
                  Register Now
                </span>
              </button>
              <button
                className="w-full sm:w-auto px-4 py-2 sm:px-6 md:px-8 lg:px-12 md:py-3 bg-white shadow-[0px_0px_4px_rgba(0,0,0,0.25)] rounded-lg md:rounded-xl"
                onClick={() => scrollToSection('contactus')}
              >
                <span className="text-[#1A365D] text-sm sm:text-base font-semibold font-montserrat">
                  Contact Us
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Right Side - Images */}
        <div className="hidden md:block w-4/5 h-100 relative mt-6 md:mt-0 overflow-visible" style={{ backgroundImage: `url(${heroBackground})` }}>
          {/* Hero Avatar (hidden on mobile screens, visible from md and above) */}
          <img
            src={homePageImage}
            alt="Hero Avatar"
            className=" md:block absolute top-[35%] right-[20%] w-3/5 h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default HomePage;