import React, { useState } from "react";

const RegistrationsTable = ({ registrations, setRegistration }) => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const sortedRegistrations = [...registrations].sort((a, b) => {
    if (sortConfig.key) {
      const sortValueA = a[sortConfig.key].toString().toLowerCase();
      const sortValueB = b[sortConfig.key].toString().toLowerCase();

      if (sortValueA < sortValueB) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (sortValueA > sortValueB) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="overflow-x-auto h-max">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {[
              { key: "id", label: "Reg ID" },
              { key: "collegeName", label: "College Name" },
              { key: "role", label: "Rep Role" },
              { key: "repName", label: "Rep Name" },
              { key: "repContact", label: "Rep Contact" },
              { key: "repEmail", label: "Rep Email" },
            ].map(({ key, label }) => (
              <th
                key={key}
                className="px-6 py-3 text-left text-xs font-medium text-[#1a365d] uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {label}
                {sortConfig.key === key && (
                  <span className="ml-2 text-sm">
                    {sortConfig.direction === "ascending" ? "▲" : "▼"}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-start">
          {sortedRegistrations.map((reg) => (
            <tr
              onClick={() => setRegistration(reg.id)}
              key={reg.id}
              className="cursor-pointer hover:bg-gray-50"
            >
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.collegeName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.role}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.repName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.repContact}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {reg.repEmail}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrationsTable;
