import React, { useEffect, useState } from 'react';
import { useFirebaseData } from './useFirebaseData';
import SearchBar from './SearchBar';
import ParticipantsTable from './ParticipantsTable';
import Pagination from './Pagination';

const StudentParticipants = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  
  const { data: loadNextPage, loadPreviousPage} = useFirebaseData('participants', itemsPerPage, setParticipants, setLoading, setError);
  const getData = () => {
  }
  
  useEffect(() => {
    getData();
  }, []);

  const handleNextPage = () => {
    loadNextPage();
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    loadPreviousPage();
    setCurrentPage(currentPage - 1);
  };

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;

  return (
    
        <div className="w-full h-full flex flex-col justify-between">
          <div className='h-max flex flex-col gap-2.5'>
            <h1 className="text-2xl font-bold text-[#1a365d] text-start mb-6">College / University Participants</h1>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <ParticipantsTable participants={participants} />
          </div>
          <Pagination
            currentPage={currentPage}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            isLastPage={participants.length < itemsPerPage}
          />
        </div>
      
  );
};

export default StudentParticipants;