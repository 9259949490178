/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  collection,
  query,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import db from "../../firebase";

const useFirebaseData = (
  collectionPath,
  itemsPerPage,
  setRegistrations,
  setLoading,
  setError
) => {
  const [lastDoc, setLastDoc] = useState(null);

  const fetchData = async (startDoc = null) => {
    setLoading(true);
    console.log("Fetching data from", collectionPath);
    try {
      const colRef = collection(db, collectionPath);

      let dataQuery = query(colRef, limit(itemsPerPage));
      console.log("Data query:", dataQuery);

      if (startDoc) {
        dataQuery = query(dataQuery, startAfter(startDoc));
      }

      const snapshot = await getDocs(dataQuery);
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched data:", fetchedData);

      if (fetchedData.length > 0) {
        setRegistrations(fetchedData);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      } else {
        setRegistrations([]);
      }
      setLoading(false);
    } catch (err) {
      setError("Error fetching data: " + err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadNextPage = () => fetchData(lastDoc);

  const loadPreviousPage = async () => {
    // Implementation remains the same as before
    // This is a simplified version and may need adjustment based on your specific requirements
  };

  return { loadNextPage, loadPreviousPage };
};

export { useFirebaseData };
