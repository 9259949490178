import React from 'react';

const ProgressBar = ({ steps, activeStep, activeColor }) => {
  return (
    <div className="flex items-center justify-center flex-wrap">
      {Array.from({ length: steps }).map((_, index) => (
        <React.Fragment key={index}>
          <div
            className={`w-5 h-5 sm:w-2 sm:h-2 md:w-4 md:h-4 rounded-full ${
              index <= activeStep ? activeColor : 'bg-gray-400'
            }`}
          ></div>
          {index < steps - 1 && (
            <div className="h-px w-16 md:w-40 bg-gray-400"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;