import React from 'react';
import Learn from '../../assets/images/learn.svg';
import Compete from '../../assets/images/compete.svg';
import Network from '../../assets/images/network.svg';

const AboutPage = () => {
  return (
    <section id="about" className="py-8 bg-gradient-to-b from-[#00FCFF]/20 via-[#1A365D]/20 to-[#00FCFF]/20 rounded-full max-w-4xl mx-auto my-10">
      <div className="px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-700">
            What is Learnathon?
          </h2>
        </div>
        <div className="flex flex-row items-center justify-between gap-3 mb-6">
          <img src={Learn} alt="Learn" className="w-2/5 h-auto" />
          <div className="flex flex-col items-center text-center w-3/5">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-700 mb-1">Learn</h3>
            <p className="text-sm text-gray-600 font-semibold">
              Immerse yourself in cutting-edge AI & ML technologies with our comprehensive training program
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between gap-3 mb-6">
          <div className="flex flex-col items-center text-center w-3/5">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-700 mb-1">Compete</h3>
            <p className="text-sm text-gray-600 font-semibold">
              Showcase your skills in zonal and state-level hackathons
            </p>
          </div>
          <img src={Compete} alt="Compete" className="w-2/5 h-auto" />
        </div>
        <div className="flex flex-col items-center gap-3">
          <img src={Network} alt="Network" className="w-2/5 h-auto" />
          <div className="flex flex-col items-center text-center w-3/5 mx-auto">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-700 mb-1">Network</h3>
            <p className="text-sm text-gray-600 font-semibold">
              Connect with industry experts and like-minded peers from top colleges
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
