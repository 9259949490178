import React from 'react';
import { useParams } from 'react-router-dom';
import RegistrationPage from '../registrationPage/registrationPage';
import NavBar from '../../layouts/navBar/navBar';
import Footer from '../../layouts/footer/footer';

const DocumentUploadPage = (props) => {
  // get mailid from route
  const { email } = useParams();

  return (
    <>
      < NavBar
        isUpload={email ? true : false}
      />
      < RegistrationPage
        isUpload={email ? true : false}
        email={email}
      />
      < Footer />
    </>
  );
};

export default DocumentUploadPage;