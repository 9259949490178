import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { ArrowLeft, Edit } from "lucide-react";
import Processing from "../../assets/images/processing.svg";
import EmptyBox from "../../assets/images/empty-data.svg";
import db from "../../firebase";

const RegistrationView = ({ registrationId, setRegistration }) => {
  const [collegeData, setCollegeData] = useState(null);
  const [editableCollegeData, setEditableCollegeData] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data for registration ID:", registrationId);
      try {
        const collegeDoc = await getDoc(
          doc(db, "registrations", registrationId)
        );
        console.log("College data:", collegeDoc.data());
        setCollegeData(collegeDoc.data());
        setEditableCollegeData(collegeDoc.data());

        const participantsSnapshot = await getDocs(
          collection(db, "registrations", registrationId, "participants")
        );
        setParticipants(
          participantsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      setEditableCollegeData(collegeData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableCollegeData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const collegeRef = doc(db, "registrations", registrationId);
      await updateDoc(collegeRef, editableCollegeData);
      setCollegeData(editableCollegeData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating college data: ", error);
    }
  };

  const filteredParticipants = participants.filter((participant) =>
    participant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredParticipants.length / itemsPerPage);
  const paginatedParticipants = filteredParticipants.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderEditableField = (label, name, value) => (
    <div className="mb-4">
      <label className="block text-sm font-bold mb-2" htmlFor={name}>
        {label}
      </label>
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
  );

  return (
    <div className="h-full w-full px-4x text-start">
      <div className="text-2xl font-bold text-[#1a365d] mb-4">
        <ArrowLeft
          onClick={() => setRegistration(null)}
          size={24}
          className="inline-block mr-2 cursor-pointer"
        />
        College Details
      </div>
      <div className="rounded-lg mb-4 p-6 text-start">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div>
            <h2 className="text-2xl font-bold text-[#1a365d]">
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={editableCollegeData?.collegeName || ""}
                  onChange={handleInputChange}
                  className="border-b-2 border-blue-500 focus:outline-none"
                />
              ) : (
                collegeData?.collegeName
              )}
            </h2>
            <p className="text-[#1a365d]">
              <span className="font-bold">Address:</span>{" "}
              {isEditing ? (
                <input
                  type="text"
                  name="address"
                  value={editableCollegeData?.collegeAddress || ""}
                  onChange={handleInputChange}
                  className="border-b-2 border-blue-500 focus:outline-none"
                />
              ) : (
                collegeData?.collegeAddress || "Not specified"
              )}
            </p>
          </div>
          <div className="flex flex-row space-x-2">
            {isEditing && (
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => {
                    setIsEditing(false);
                  }}
                  className="hover:shadow  font-bold py-2 px-4 rounded"
                >
                  <ArrowLeft size={16} className="inline-block mr-2" />
                  Back
                </button>
              </div>
            )}
            <button
              onClick={isEditing ? handleSave : handleEditToggle}
              className="mt-4 sm:mt-0 hover:shadow border border-1 border-[#4A5568] font-bold py-2 px-4 rounded-lg"
            >
              {isEditing ? "Save" : "Edit"}
              <Edit size={16} className="inline-block ml-2" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            {isEditing ? (
              <>
                {renderEditableField(
                  "Representative",
                  "representative",
                  editableCollegeData?.role || ""
                )}
                {renderEditableField(
                  "Representative Name",
                  "representativeName",
                  editableCollegeData?.repName || ""
                )}
                {renderEditableField(
                  "Representative Contact",
                  "representativeContact",
                  editableCollegeData?.repContact || ""
                )}
                {renderEditableField(
                  "Representative Email",
                  "representativeEmail",
                  editableCollegeData?.repEmail || ""
                )}
              </>
            ) : (
              <>
                <p>
                  <span className="font-bold">Representative:</span>{" "}
                  {collegeData?.role || "Not Specified"}
                </p>
                <p>
                  <span className="font-bold">Representative Name:</span>{" "}
                  {collegeData?.repName || "Not Specified"}
                </p>
                <p>
                  <span className="font-bold">Representative Contact:</span>{" "}
                  {collegeData?.repContact || "Not Specified"}
                </p>
                <p>
                  <span className="font-bold">Representative Email:</span>{" "}
                  {collegeData?.repEmail || "Not Specified"}
                </p>
              </>
            )}
          </div>
          <div>
            {isEditing ? (
              <>
                {renderEditableField(
                  "Training Dates",
                  "trainingDates",
                  editableCollegeData?.trainingDates || ""
                )}
                {renderEditableField(
                  "Trainer Names",
                  "trainerNames",
                  editableCollegeData?.trainerNames || ""
                )}
                {renderEditableField(
                  "Trainer Contacts",
                  "trainerContacts",
                  editableCollegeData?.trainerContacts || ""
                )}
                {renderEditableField(
                  "Trainer Emails",
                  "trainerEmails",
                  editableCollegeData?.trainerEmails || ""
                )}
              </>
            ) : (
              <>
                <p>
                  <span className="font-bold">Training Dates:</span>{" "}
                  {collegeData?.trainingDates || "Not specified"}
                </p>
                <p>
                  <span className="font-bold">Trainer Names:</span>{" "}
                  {collegeData?.trainerNames || "Not specified"}
                </p>
                <p>
                  <span className="font-bold">Trainer Contacts:</span>{" "}
                  {collegeData?.trainerContacts || "Not specified"}
                </p>
                <p>
                  <span className="font-bold">Trainer Emails:</span>{" "}
                  {collegeData?.trainerEmails || "Not specified"}
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-bold text-[#1a365d] mb-4">Participants</h2>
      <input
        type="text"
        placeholder="Search Participant"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />

      {collegeData?.fileURL ? (
        collegeData?.processedData ? (
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Reg ID</th>
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Course</th>
                  <th className="px-4 py-2 text-left">Year</th>
                  <th className="px-4 py-2 text-left">Contact</th>
                  <th className="px-4 py-2 text-left">Email</th>
                </tr>
              </thead>
              <tbody>
                {paginatedParticipants.map((participant, index) => (
                  <tr
                    key={participant.id}
                    className={index % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    <td className="px-4 py-2">{participant.regId}</td>
                    <td className="px-4 py-2">{participant.name}</td>
                    <td className="px-4 py-2">{participant.course}</td>
                    <td className="px-4 py-2">{participant.year}</td>
                    <td className="px-4 py-2">{participant.contact}</td>
                    <td className="px-4 py-2">{participant.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          processingData()
        )
      ) : (
        noData()
      )}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <div className="flex gap-2">
          {[...Array(pageCount)].map((_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`py-2 px-4 rounded ${
                currentPage === i + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, pageCount))
          }
          disabled={currentPage === pageCount}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const processingData = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="text-center">
        <img src={Processing} alt="" />
        <div className="text-2xl font-bold text-[#1a365d]">Processing...</div>
        <div className="text-gray-500">please check after sometime</div>
      </div>
    </div>
  );
};

const noData = () => {
  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="text-center">
        <img src={EmptyBox} alt="" />
        <div className="text-2xl font-bold text-[#1a365d]">
          Not yet uploaded
        </div>
        <div className="text-gray-500">
          please check with the representative
        </div>
      </div>
    </div>
  );
};

export default RegistrationView;
