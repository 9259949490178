import React from 'react';
import hackathon from "../../assets/images/hackathon.svg";
import bgComponent from "../../assets/images/bg-components.png";

const HackathonDetails = () => {
    return (
        <div  className="bg-gradient-to-b from-[#42C8C9] via-[#1A365D] to-black h-max">
        <div id='hackathon' className="flex flex-col justify-center items-center md:p-20 sm:p-5" style={{ backgroundImage: `url(${bgComponent})` }}>
          <div>
            <div className="text-center text-[#E2E8F0] font-montserrat font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
              Hackathon
              <div className="text-center text-[#E2E8F0] font-montserrat text-lg sm:text-xl md:text-2xl">
                <span className="font-normal">Compete on real-work problem statements </span>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center" >
            <img
              src={hackathon}
              alt="Hackathon"
              className="object-contain w-2/5 "
            />
            <div className="w-3/5 flex flex-col justify-start items-start gap-20 p-10" >
              <div className="flex flex-col justify-start text-white text-base sm:text-xl text-start gap-10">
                <li>A one-day hackathon focused on AI/ML technologies applied to the insurance industry, where participants will work on real-world case studies. </li>
                <li>
                  Problem-Statements will be from the domains,
                  <div className="text-white text-base sm:text-xl text-start px-10">
                    <li>Healthcare</li>
                    <li>Banking and Finance</li>
                    <li>Manufacturing Industry</li>
                    <li>Insurance Industry</li>
                    <li>Clinical Research</li>
                  </div>
                </li>
              </div>
              <button className="w-max flex flex-row justify-center items-center gap-4 px-12 py-3 shadow-md rounded-md border text-[#FFFFFF] md:text-lg sm:text-normal font-semibold">
                Schedule
                <i className="fas fa-arrow-down"></i>
              </button>
            </div>
          </div>
          <div className="relative w-full h-auto p-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">08:00 AM - 09:00 AM</div>
                <div className="text-black text-base font-bold font-openSans">Registration and Breakfast</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">09:00 AM - 09:30 AM</div>
                <div className="text-black text-base font-bold font-openSans">Opening Ceremony</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">09:30 AM - 10:00 AM</div>
                <div className="text-black text-base font-bold font-openSans">Case Study Presentation</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">10:00 AM - 01:00 PM</div>
                <div className="text-black text-base font-bold font-openSans">Hacking Session 1</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">01:00 PM - 02:00 PM</div>
                <div className="text-black text-base font-bold font-openSans">Lunch Break</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">02:00 PM - 05:00 PM</div>
                <div className="text-black text-base font-bold font-openSans">Hacking Session 2</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">05:00 PM - 06:00 PM</div>
                <div className="text-black text-base font-bold font-openSans">Submission of Final Solutions</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">06:00 PM - 07:00 PM</div>
                <div className="text-black text-base font-bold font-openSans">Presentations and Judging</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">07:00 PM - 07:30 PM</div>
                <div className="text-black text-base font-bold font-openSans">Award Ceremony</div>
              </div>
              <div className="px-2.5 py-5 bg-white rounded-lg flex flex-col justify-center items-center gap-2.5">
                <div className="text-black text-sm font-normal font-openSans">07:00 PM - 07:30 PM</div>
                <div className="w-48 text-center text-black text-base font-bold font-openSans">Networking and Closing Remarks</div>
              </div>
            </div>
            <div className="absolute inset-0 flex flex-col justify-evenly items-center  w-full h-auto px-20">
              <div className="w-full flex justify-evenly items-center">
                <div className="w-16 h-px border border-white"></div>
                <div className="w-16 h-px border border-white"></div>
                <div className="w-16 h-px border border-white"></div>
              </div>
              <div className="w-full flex justify-evenly items-center">
                <div className="w-16 h-px border border-white"></div>
                <div className="w-16 h-px border border-white"></div>
                <div className="w-16 h-px border border-white"></div>
              </div>
              <div className="w-full flex justify-evenly items-center">
                <div className="w-16 h-px border border-white"></div>
                <div className="w-16 h-px border border-transparent"></div>
                <div className="w-16 h-px border border-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HackathonDetails;