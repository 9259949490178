/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Home, GraduationCap, Users, Settings, LogOut } from 'lucide-react';
import Logo from '../../assets/images/logo.svg';
import Dashboard from "../../pages/adminDashboard/adminDashboard";
import Registrations from "../../pages/registrations/registrations";
import Participants from "../../pages/participants/participants";

const Sidebar = (props) => {
  const [activeSection, setActiveSection] = useState('home');

  const handleSectionClick = (section, component) => {
    setActiveSection(section);
    props.setAdminPage(component);
  };

  return (
    <div className={`w-1/5 h-full flex flex-col justify-between`}>
      <div className="h-max flex flex-col gap-10">
        <div className="h-max flex justify-between items-center">
          <img src={Logo} alt="Logo" className="h-12" />
        </div>
        <ul className="h-max space-y-6">
          <li>
            <button
              onClick={() => handleSectionClick('home', <Dashboard />)}
              className={`flex items-center space-x-3 ${activeSection === 'home' ? 'text-blue-900 font-semibold' : 'text-gray-600 hover:text-blue-900'}`}
            >
              <Home size={20} />
              <span>Home</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleSectionClick('registrations', <Registrations />)}
              className={`flex items-center space-x-3 ${activeSection === 'registrations' ? 'text-blue-900 font-semibold' : 'text-gray-600 hover:text-blue-900'}`}
            >
              <GraduationCap size={20} />
              <span>College/University</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => handleSectionClick('participants', <Participants />)}
              className={`flex items-center space-x-3 ${activeSection === 'participants' ? 'text-blue-900 font-semibold' : 'text-gray-600 hover:text-blue-900'}`}
            >
              <Users size={20} />
              <span>Participants</span>
            </button>
          </li>
        </ul>
      </div>
      <ul className="space-y-4 h-max">
        <li>
          <a href="#" className="flex items-center space-x-3 text-gray-600 hover:text-blue-900">
            <Settings size={20} />
            <span>Settings</span>
          </a>
        </li>
        <li>
          <a href="#" className="flex items-center space-x-3 text-gray-600 hover:text-blue-900">
            <LogOut size={20} />
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
