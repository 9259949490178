import React from "react";
import partnersData from '../../store/partners.json';

const PartnersPage = () => {
	return (
		<div className="container mx-auto p-4 my-10">
			<div className="w-full flex flex-col items-center gap-8">
				<div className="w-full flex justify-between items-center">
					<div>
						<span className="text-black text-4xl font-bold font-montserrat">
							Our{" "}
						</span>
						<span className="text-[#2c7a7b] text-4xl font-black italic font-iowan">
							PARTNERS
						</span>
					</div>
					<div className="h-10 px-12 py-2.5 rounded-2xl shadow border border-[#1a365d] flex items-center gap-2.5">
						<div className="text-[#1a365d] text-base font-semibold font-montserrat">
							See all
						</div>
					</div>
				</div>
				<div className="w-full flex flex-wrap justify-center gap-12">
					{partnersData.map((partner, index) => (
						<img
							key={index}
							className="w-[200px] h-[200px] rounded-full object-contain items-center"
							src={partner.logo}
							alt={partner.name}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default PartnersPage;
