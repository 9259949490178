import React from "react";
import Sidebar from "../sidebar/sidebar";
import { Outlet } from "react-router-dom";
import bgComponent from "../../assets/images/bg-components.png";

const AdminLayout = (props) => {
  return (
      <div className="relative h-screen w-screen" style={{ backgroundImage: `url(${bgComponent})` }} >
        <div className="absolute top-0 left-[20%] w-[2px] h-full bg-[#E2E8F0]"></div>
        <div className="p-[50px] w-screen h-full flex flex-row bg-transparent">
        <Sidebar
          adminPage={props.adminPage}
          setAdminPage={props.setAdminPage}
        />
        <div className="w-4/5 h-full flex flex-col justify-start items-center gap-[100px]">
          <Outlet />
        </div>
      </div>
      </div>
  );
};

export default AdminLayout;
