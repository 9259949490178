import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Pagination = ({ currentPage, onPrevious, onNext, isLastPage }) => (
  <div className="mt-6 h-max flex items-center justify-between">
    <button
      className="flex items-center text-[#1a365d]"
      onClick={onPrevious}
      disabled={currentPage === 1}
    >
      <ChevronLeft size={20} />
      <span>Previous</span>
    </button>
    <div className="flex items-center space-x-2">
      <span>Page {currentPage}</span>
    </div>
    <button
      className="flex items-center text-[#1a365d]"
      onClick={onNext}
      disabled={isLastPage}
    >
      <span>Next</span>
      <ChevronRight size={20} />
    </button>
  </div>
);

export default Pagination;
